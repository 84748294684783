export type JuweteamMember = {
  memberName: string;
  memberPosition: string;
  imgSrc: string;
};

export const juweteam_members: JuweteamMember[] = [
  {
    memberName: "Zuza Gajewska",
    memberPosition: "Koordynatorka Główna",
    imgSrc: "/images/juweteam_webp/zuza.webp",
  },
  {
    memberName: "Natalia Wrona",
    memberPosition: "Młodsza Koordynatorka Główna",
    imgSrc: "/images/juweteam_webp/wrona.webp",
  },
  {
    memberName: "Tomek Bębnowicz",
    memberPosition: "Koordynator ds. Koncertów",
    imgSrc: "/images/juweteam_webp/tomasz.webp",
  },
  {
    memberName: "Tomek Horodelski",
    memberPosition: "Młodszy koordynator ds. Koncertów",
    imgSrc: "/images/juweteam_webp/tomek.webp",
  },
  {
    memberName: "Wojtek Żmuda",
    memberPosition: "Koordynator ds. Współpracy Zewnętrznej",
    imgSrc: "/images/juweteam_webp/wojtini.webp",
  },
  {
    memberName: "Julia Krawczyk",
    memberPosition: "Koordynatorka ds. Współpracy Zewnętrznej",
    imgSrc: "/images/juweteam_webp/julia.webp",
  },
  {
    memberName: "Gosia Kobus",
    memberPosition: "Młodsza koordynatorka ds. Współpracy Zewnętrznej",
    imgSrc: "/images/juweteam_webp/gosia.webp",
  },
  {
    memberName: "Staś Królak",
    memberPosition: "Młodszy koordynator ds. Współpracy Zewnętrznej",
    imgSrc: "/images/juweteam_webp/stasiu.webp",
  },
  {
    memberName: "Ania Okulczyk",
    memberPosition: "Koordynatorka ds. Logistyki",
    imgSrc: "/images/juweteam_webp/ania.webp",
  },
  {
    memberName: "Kalina Kowalewska",
    memberPosition: "Młodsza koordynatorka ds. Logistyki",
    imgSrc: "/images/juweteam_webp/kalina.webp",
  },
  {
    memberName: "Przemek Karolak",
    memberPosition: "Koordynator ds. Atrakcji",
    imgSrc: "/images/juweteam_webp/przemek.webp",
  },
  {
    memberName: "Wiktoria Winiarska",
    memberPosition: "Koordynatorka ds. Promocji",
    imgSrc: "/images/juweteam_webp/wiktoria.webp",
  },
  {
    memberName: "Julka Feliksbrot",
    memberPosition: "Koordynatorka ds. Promocji",
    imgSrc: "/images/juweteam_webp/julka.webp",
  },
  {
    memberName: "Natalia Niewierowicz",
    memberPosition: "Młodsza koordynatorka ds. Promocji",
    imgSrc: "/images/juweteam_webp/natalia.webp",
  },
  {
    memberName: "Kamila Czak-Żukowska",
    memberPosition: "Młodsza koordynatorka ds. Promocji",
    imgSrc: "/images/juweteam_webp/kamila.webp",
  },
  {
    memberName: "Michał Wiktorzak",
    memberPosition: "Koordynator ds. Kreatywnych",
    imgSrc: "/images/juweteam_webp/wiktorzak.webp",
  },
  {
    memberName: "Kaja Wiktorzak",
    memberPosition: "Projektantka graficzna",
    imgSrc: "/images/juweteam_webp/bulba.webp",
  },
  {
    memberName: "Dawid Wierzejewski",
    memberPosition: "Projektant graficzny",
    imgSrc: "/images/juweteam_webp/dawid.webp",
  },
  {
    memberName: "Bartłomiej Kucisz",
    memberPosition: "Projektant graficzny",
    imgSrc: "/images/juweteam_webp/bartek.webp",
  },
];