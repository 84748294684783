import "./jakDojade.scss"

function JakDojade() {

    return (
    <div className="jak-dojade">
      <div className="col-10 mx-auto">
        <div className="jakdojade-container">
          <div className="jakdojade-logo-wraper">
            <img alt="jakdojade logo" className="logo" src="/images/jakdojade.png"/>
          </div>
          <div id="jakdojade" className="jakdojade-widget-wraper">
              {
                // @ts-ignore
                <jakdojade-planner-widget city-symbol="WARSZAWA" locale="pl" max-res="20" theme="wtk-red"/>
              }
            <script src="https://jakdojade.pl/widgets/elements.js"></script>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JakDojade;
