import React, { Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./MessageModal.scss"

interface MessageModalProps {
    status: number;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}

function MessageModal(props: MessageModalProps) {
    let modalProporties = {
        title: "",
        body: "",
    }
    if (props.status === 201 || props.status === 200 || props.status === 73 /* antispam - fake success */ ) {
        modalProporties.title = "Sukces";
        modalProporties.body = "Udało się wysłać wiadomość!"
    }
    else if (props.status === 413) {
        modalProporties.title = "Slowchat jest włączony";
        modalProporties.body = "Poczekaj przed wysłaniem następnej wiadomości."
    }
    else if (props.status === 69) {
        modalProporties.title = "Chat zablokowany";
        modalProporties.body = "Chat zostanie odblokowany wraz z rozpoczęciem wydarzenia."
    }
    else if (props.status === 70) {
        modalProporties.title = "Chat zablokowany";
        modalProporties.body = "Wydarzenie zakończone - chat został zablokowany, do zobaczenia za rok!";
    }
    else if (props.status === 71) {
        modalProporties.title = "Za mało znaków";
        modalProporties.body = 'Pole "Autor" musi zawierać przynajmniej 3 znaki.';
    }
    else if (props.status === 72) {
        modalProporties.title = "Za mało znaków w wiadomości";
        modalProporties.body = 'Wiadomość musi zawierać przynajmniej 5 znaków.';
    }
    else {
        modalProporties.title = "Wystąpił błąd";
        modalProporties.body = "Nie udało się wysłać wiadomości. Spróbuj ponownie później."
    }

    let show = props.show;
    let setShow = props.setShow;

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalProporties.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalProporties.body}</Modal.Body>
            <Modal.Footer>
                <Button className="modal-button" onClick={() => setShow(false)}>
                    Zamknij
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MessageModal;