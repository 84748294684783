import React from "react";
import "../juweteam/juweteam.scss";
import Teamcard from "src/components/Teamcard";
import {juweteam_members} from "./juweteamContent";

function Juweteam() {
  return (
    <div className="juweteam-container">
      <div className="col-10 mx-auto">
        <h1>Juweteam 2024</h1>
        <div className="responsive-container-block teamcard-container">
          {juweteam_members.map((member) => (
            <Teamcard
              memberName={member.memberName}
              memberPosition={member.memberPosition}
              imgSrc={member.imgSrc}
              key={member.memberName}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Juweteam;
