import React from "react";
import "./Teamcard.scss";
import Col from "react-bootstrap/esm/Col";

function Teamcard(props: any) {
  return (
    <Col fluid="true" className="team-card">
      <div className="team-img-wrapper">
        <img
          alt="Team member"
          className="team-img"
          src={props.imgSrc}
        />
      </div>
      <div className="team-card-content">
        <p className="text-blk name">{props.memberName}</p>
        <p className="text-blk position">{props.memberPosition}</p>
      </div>
    </Col>
    // <div className="teamcard">
    //   <div className="teamcard-img-wrapper">
    //     <img className="teamcard-img" src={props.imgSrc} />
    //   </div>
    //   <div className="teamcard-contents">
    //     <p className="teamcard-member-name">{props.memberName}</p>
    //     <p className="teamcard-member-position">{props.memberPosition}</p>
    //   </div>
    // </div>
  );
}

export default Teamcard;
