import "../../App.scss";
import "./partners.scss";
import Card from "react-bootstrap/Card";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import {Link} from "react-router-dom";

function Partners() {
  const imagesDict = [
    "eska",
    "eskarock",
    "avocado",
    "fap",
    "focus",
    "perspektywy",
    "strefamusic",
    "thaiwok",
    "ticketclub",
  ];

  const imagesDict2 = [
    "stodola",
    "bic",
    "dlastudenta",
    "jakdojade",
    "mazowsze",
    "pttk",
    "stage24",
    "tvpw",
    "traficar",
  ];

  const responsive = {
    0: { items: 2 },
    290: { items: 3 },
    880: { items: 4 },
    1200: { items: 5 },
    1400: { items: 6 },
  };

  return (
    <div className="partners">
      <div className="col-10 mx-auto">
        <h1>Organizatorzy</h1>
        <div className="organisers-container">
          <Link className="organiser" to="https://www.pw.edu.pl/">
            <img
              alt="Politechnika Warszawska"
              src="/images/organisers/politechnika_warszawska.png"
              className="my-auto sponsor-img"
              key="organiser_pw"
              onDragStart={(e) => e.preventDefault()}
            />
          </Link>
          <Link className="organiser sspw" to="https://sspw.pl/">
            <img
              alt="Samorząd Studentów Politechniki Warszawskiej"
              src="/images/organisers/sspw.png"
              className="my-auto sponsor-img"
              key="organiser_sspw"
              onDragStart={(e) => e.preventDefault()}
            />
          </Link>
        </div>
        <h1>Partnerzy</h1>
        <div className="alice-carousel-wrapper">
          <AliceCarousel
            responsive={responsive}
            animationType="fadeout"
            autoPlay={true}
            animationDuration={3000}
            autoPlayInterval={1000}
            autoPlayControls={false}
            autoPlayStrategy="none"
            disableButtonsControls={true}
            disableDotsControls={true}
            infinite={true}
            mouseTracking={false}
            touchTracking={false}
            touchMoveDefaultEvents={false}
            items={imagesDict.map((image, i) => (
              <Card className="sponsor-card">
                <img
                  alt={image}
                  src={`/images/sponsors/${image}.png`}
                  className="my-auto sponsor-img"
                  key={i}
                  onDragStart={(e) => e.preventDefault()}
                />
              </Card>
            ))}
          />
          <div className="opacity"/>
        </div>
        <div style={{height: "1rem"}}/>
        <div className="alice-carousel-wrapper">
          <AliceCarousel
            responsive={responsive}
            animationType="fadeout"
            autoPlay={true}
            animationDuration={3000}
            autoPlayInterval={1000}
            autoPlayControls={false}
            autoPlayStrategy="none"
            disableButtonsControls={true}
            disableDotsControls={true}
            infinite={true}
            mouseTracking={false}
            touchTracking={false}
            touchMoveDefaultEvents={false}
            items={imagesDict2.map((image, i) => (
              <Card className="sponsor-card">
                <img
                  alt={image}
                  src={`/images/sponsors/${image}.png`}
                  className="my-auto sponsor-img"
                  key={i}
                  onDragStart={(e) => e.preventDefault()}
                />
              </Card>
            ))}
          />
          <div className="opacity"/>
        </div>
      </div>
    </div>
  );
}

export default Partners;
