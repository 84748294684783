import {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import "./Navbar.scss";
import {HashLink} from "react-router-hash-link";
import {Logo} from "./Logo";
import {FaChevronDown, FaChevronRight, FaChevronUp} from "react-icons/fa6";

function Navbar() {
  const location = useLocation();

  const [click, setClick] = useState(false);
  const [ticketsClicked, setTicketsClicked] = useState<boolean>(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setTicketsClicked(false);
    setClick(false);
  }


  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener("resize", showButton);

  const offset = 75;
  const scrollWithOffset = (el: HTMLElement) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth"
    });
  }

  return (
    <nav className={"navbar-site".concat(location.pathname === '/' ? " absolute" : "")}>
      <div className="navbar-container">
        <HashLink to="/#" onClick={closeMobileMenu} className="navbar-logo">
          <Logo/>
        </HashLink>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}/>
        </div>
        <div className={click ? "nav-menu active" : "nav-menu"}>
          <div className="nav-item">
            <div className="nav-links" onClick={() => setTicketsClicked((prevState) => !prevState)}>
              KUP BILET
              {ticketsClicked ?
                <FaChevronUp className="mobile-menu-chevron-up-down"/> :
                <FaChevronDown className="mobile-menu-chevron-up-down"/>
              }
            </div>
            <div className={`tickets-drawer ${ticketsClicked && "tickets-drawer-active"}`}>
              <div className="drawer-nav-item">
                <Link
                  to="https://ticketclub.pl/varsonalia-pw/"
                  className="nav-links tickets"
                  onClick={closeMobileMenu}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TicketClub
                  <FaChevronRight className="mobile-menu-chevron-right"/>
                </Link>
              </div>
              <div className="drawer-nav-item">
                <Link
                  to="https://stage24.pl/events/varsonalia2024-stadion-syrenki-2188"
                  className="nav-links tickets"
                  onClick={closeMobileMenu}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stage24
                  <FaChevronRight className="mobile-menu-chevron-right"/>
                </Link>
              </div>
            </div>
          </div>
          <div className="nav-item">
            <HashLink
              to="/#lineup"
              smooth
              className="nav-links"
              onClick={closeMobileMenu}
              scroll={scrollWithOffset}
            >
              LINE-UP
            </HashLink>
          </div>
          <div className="nav-item">
            <HashLink
              to="/#archive"
              smooth
              className="nav-links"
              onClick={closeMobileMenu}
              scroll={scrollWithOffset}
            >
              GALERIA
            </HashLink>
          </div>
          {/* <div className="nav-item">
                <HashLink
                  to="/#message"
                  smooth
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  WYŚLIJ WIADOMOŚĆ NA TELEBIM
                </HashLink>
              </div> */}

          <div className="nav-item">
            <HashLink
              to="/#juweteam"
              smooth
              className="nav-links"
              onClick={closeMobileMenu}
              scroll={scrollWithOffset}
            >
              JUWETEAM
            </HashLink>
          </div>
          <div className="nav-item">
            <HashLink
              to="/#faq"
              smooth
              className="nav-links"
              onClick={closeMobileMenu}
              scroll={scrollWithOffset}
            >
              FAQ
            </HashLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
