import React, {createContext, useEffect, useState} from "react";

export const ImageContext: React.Context<{ all: Array<string>; year: Map<string, Array<string>> }> = createContext({
  year: new Map<string, Array<string>>(),
  all: new Array<string>()
});

export function ImageContextProvider(props: { children: React.ReactNode}) {
  const [imagesMap] = useState<Map<string, Array<string>>>(new Map());
  const [allImagesArray] = useState<Array<string>>(new Array<string>());

  useEffect(() => {
    const allArchiveImagesContext = require.context(`../../public/images/varso_gallery/`, true);
    allArchiveImagesContext.keys().forEach((key) => {
      const image = allArchiveImagesContext(key);
      const year = key.slice(2, 6);
      if (imagesMap.get(year) === undefined) {
        imagesMap.set(year, new Array<string>());
      }
      if (!allImagesArray.includes(image)) {
        imagesMap.get(year)?.push(image);
        allImagesArray.push(image);
      }
    })
  }, []);

  return <ImageContext.Provider value={{year: imagesMap, all: allImagesArray}}>
    {props.children}
  </ImageContext.Provider>
}