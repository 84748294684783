import React from "react";
import { useState } from "react";
import "../../App.scss";
import "./FAQ.scss";
import Accordion from 'react-bootstrap/Accordion';
import { faqContent } from './faqContent';

function FAQ() {
  const [selected, setSelected] = useState<number>(0);

  const toggle = (i: number) => {
    if (selected === i) {
      return setSelected(0);
    }
    setSelected(i);
  };

  return (
    <div className="faq-container">
      <div className={window.innerWidth > 960 ? "col-10 mx-auto" : ""}>
        <h1>Często zadawane pytania</h1>
        <Accordion flush={window.innerWidth <= 960} >
          {faqContent.map((item, i) => (
            <Accordion.Item eventKey={i.toString()} key={i}>
              <Accordion.Header>{item.question}</Accordion.Header>
              <Accordion.Body>{item.answer}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default FAQ;
