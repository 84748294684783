import React, {useRef} from "react";
import {Link} from "react-router-dom";
import "./Footer.scss";
import {HashLink} from "react-router-hash-link";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function Footer() {
  const centerPartRef = useRef<HTMLTableColElement>(null);

  return (
    <div className="footer-container">
      <section className="social-media">
        <Row className="social-media-wrap">
          <Col xs={{span: 4}} className="left-col">
            <HashLink to="/#" smooth className="footer-logo-hashlink">
              <img src="images/varsonalia_logo.webp" className="footer-logo" alt="Varsonalia PW 2024 logo"/>
            </HashLink>
            <Link to="https://sspw.pl/" className="footer-logo-hashlink footer-sspw">
              <img src="images/sspw_white.png" className="footer-logo" alt="SSPW logo"/>
            </Link>
          </Col>
          <Col className="center-col" ref={centerPartRef}>
            <small className="website-rights">Varsonalia PW 2024 {window.innerWidth > 960 ? <>|</> : <></>} <Link
              to="https://drive.google.com/file/d/1VcNgZZ_R1WKccUjnzZgplQhS3bPFF4HX/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Regulamin
            </Link>
            </small>
            <div className="footer-authors">
              <small>Copyright © | Mateusz Gietka, Kuba Jałoszyński, Szymon Polański, Andrzej Słowiński.</small>
              <div style={{paddingTop: 6, paddingBottom: 6}}>Maintained by <a href="https://www.linkedin.com/in/grzegorz-rusinek/" rel="noopener noreferrer">Grzegorz Rusinek</a>.</div>
            </div>
          </Col>
          <Col xs={{span: 4}} align="center">
            <div className="social-icons">
              <Link
                className="social-icon-link facebook"
                to="https://www.facebook.com/events/1040214107232402"
                target="_blank"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                className="social-icon-link instagram"
                to="https://www.instagram.com/juwenaliapw/"
                target="_blank"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram"></i>
              </Link>
              <Link
                className="social-icon-link tiktok"
                to="https://www.tiktok.com/@juwenaliapw"
                target="_blank"
                aria-label="TikTok"
              >
                <i className="fab fa-tiktok"></i>
              </Link>
              <Link
                className="social-icon-link linkedin"
                to="https://www.linkedin.com/company/juwenalia-pw/"
                target="_blank"
                aria-label="LinkedIn"
              >
                <i className="fab fa-linkedin"></i>
              </Link>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default Footer;
