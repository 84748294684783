import {useCallback, useLayoutEffect, useState} from 'react';

export function useWindowScroll() {
  const [scroll, setScroll] = useState(0);

  const handleWindowScroll = useCallback(() => {
    setScroll(window.scrollY);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);
    handleWindowScroll();
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [handleWindowScroll]);
  return scroll;
}