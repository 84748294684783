import React, {useEffect, useState} from "react";
import "./homePageArchive.scss";
import Archivecard from "../../components/Archivecard";
import {events} from "./archiveContent";

function GalleryCards() {
  const [animationEventId, setAnimationEventId] = useState(0);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (intervalID === undefined) {
      interval = setInterval(function () {
        let newIdx = animationEventId;
        while (newIdx === animationEventId) {
          newIdx = Math.floor(Math.random() * events.length) % events.length;
        }
        setAnimationEventId(newIdx);
      }, 2000);
      setIntervalID(interval);
    }
    return () => {
      clearInterval(interval);
      setIntervalID((prevState) => prevState === interval ? undefined : prevState);
    }
  }, []);

  return (
    <div className="archive-cards">
      <div className="col-10 mx-auto">
        <h1>Galeria zdjęć</h1>
        <div className="grid-container">
          {events.map((event, index) => (
            <div className="image-wrapper" key={event.year}>
              <Archivecard event={event} changePicture={animationEventId === index} key={event.name}/>
            </div>
          ))}
        </div>
        <div className="image-columns">

        </div>
      </div>
    </div>
  );
}

export default GalleryCards;
