import React from "react";
import "./404.scss"

function NotFound() {
  return (
    <div className="body-404">
      <h1 style={{color: "white"}}>Nie znaleziono strony</h1>
    </div>
  )
}

export default NotFound;
