import React from "react";
import "../../App.scss";
import "./home.scss";
import HomepageVideo from "./homepageVideo";
import LineupCards from "../lineup/LineupCards";
import Partners from "../partners/partners";
import FAQ from "../FAQ/FAQ";
import GalleryCards from "../archive/homePageArchive";
import JakDojade from "../jakdojade/jakDojade";
import Juweteam from "../juweteam/juweteam";

function Home() {
  return (
    <div className="homepage">
      <section id="top">
        <HomepageVideo/>
      </section>
      <section id="lineup">
        <LineupCards/>
      </section>
      <section id="archive">
        <GalleryCards/>
      </section>
      <section id="juweteam">
        <Juweteam/>
      </section>
      <section id="partners">
        <Partners/>
        {window.innerWidth > 960 && <JakDojade/>}
      </section>
      <section id="faq">
        <FAQ/>
      </section>
    </div>
  );
}

export default Home;
