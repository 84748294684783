import React, {useContext, useEffect, useState} from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import {ImageContext} from "../../Context/ImageContextProvider";
import {Loader} from "../../components/Loader";
import {Link, useSearchParams} from "react-router-dom";
import "./galleryFolderView.scss";
import {events} from "./archiveContent";
import {FaXmark} from "react-icons/fa6";
import {useWindowSize} from "../../Hooks/useWindowSize";

function GalleryFolderView() {
  const curr_url = window.location.pathname.split("/");
  const curr_year = curr_url[2];

  const windowSize = useWindowSize();
  const imagesContext = useContext(ImageContext);
  const [images, setImages] = useState<string[]>([]);

  const [searchParams] = useSearchParams();
  const imageParam = parseImageId(searchParams.get("image"));
  const [galleryPageScroll, setGalleryPageScroll] = useState<number>(window.scrollY);

  function parseImageId(queryParam: string | null) {
    if (queryParam === null)
      return undefined;
    const paramAsNumber = parseInt(queryParam);
    if (isNaN(paramAsNumber))
      return undefined;
    if (paramAsNumber >= images.length)
      return 0;
    return paramAsNumber;
  }

  useEffect(() => {
    setImages(imagesContext.year.get(curr_year) ?? []);
  }, [imagesContext, curr_year]);

  useEffect(() => {
    window.scrollTo({behavior: "instant", top: imageParam === undefined ? galleryPageScroll : 0});
  }, [imageParam]);

  if (images.length === 0)
    return <div className="archive-loader-div">
      <Loader/>
    </div>

  if (imageParam !== undefined)
    return (
      <div className="archive-image-view">
        <ImageGallery
          showFullscreenButton={windowSize[0] > 960}
          showPlayButton={false}
          lazyLoad={true}
          onImageLoad={() => <Loader/>}
          items={images.map((image) => ({
            original: image
          }))}
          startIndex={imageParam}
        />
        <Link className="exit-link" to={""}>
          <FaXmark className="exit-icon"/>
        </Link>
      </div>
    );

  return (
    <div className="archive-gallery-view">
      <h1>{events.find((event) => event.year.toString() === curr_year)?.name ?? `Juwenalia PW ${curr_year}`}</h1>
      <div className="image-columns">
        {images.map((image, index) => (
          <div className="image-wrapper" key={image}>
            <Link to={`?image=${index}`} onClick={() => setGalleryPageScroll(window.scrollY)}>
              <img src={image} className="image" alt={image}/>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GalleryFolderView;
