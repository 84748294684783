export const antispam_regex_templates: string[] = [
 // rzeczowniki
'(nie)?c?huj(|a|owi|em|u|e|ów|om|ami|ach)',
'(nie)?siur(|a|owi|em|rze|y|ów|om|ami|ach)',
'(nie)?(c?huj|siur)(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?(c?huj|siur|kutas|fiut)(eczek|eczka|eczkowi|eczkiem|eczku|eczki|eczków|eczkom|eczkami|eczkach)',
'(nie)?(siuras|kutas)(|a|owi|em|ie|y|ów|om|ami|ach)',
'(nie)?(kutas|siuras|fiut)(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?(fiu)(t|ta|towi|tu|tem|cie|ty|tów|tom|tami|tach)',
'(nie)?kutas(ik|ika|ikowi|ikiem|iku|iki|ików|ikom|ikami|ikach)',
'(nie)?jaj(co|ca|com|cem|cze|cu|ca|ec|com|cami|cach)',
'(nie)?cip(a|y|ie|ą|o|y||om|ami|ach)',
'(nie)?cip(ecz)?(ka|ki|ce|ką|ko|ek|kom|kami|kach)',
'(nie)?pi(zda|zdy|ździe|zdą|zdo|zdy|zd|zdom|zdami|zdach)',
'(nie)?(cipul|pizdul)(eńka|eńki|eńce|eńkę|eńką|eńko|eńki|eniek|eńkom|eńkami|eńkach)',
'(nie)?dup(a|y|ie|ą|o||om|ami|ach)',
'(nie)?dup(ecz)?(ka|ki|ce|ką|ek|ko|kom|kami|kach)',
'(nie)?(dup|cip)(cia|ci|cię|cią|cio|ciu|cie|ciom|ciami|ciach)',
'(nie)?sra(ka|ki|ce|ką|ko|k|kom|kami|kach)',
'(nie)?sracz(ka|ki|ce|ką|ko|czek|kom|kami|kach)',
'(nie)?gów(no|na|nu|nem|nie|na|ien|nom|nami|nach)',
'(nie)?gówien(ecz)?(ko|ka|ku|kiem|ku|ek|kom|kami|kach)',
'(nie)?bździn(a|y|ę|ie|ę|ą|ie|o|y||om|ami|ach)',
'(nie)?bździn(ecz)?(ka|ki|ce|kę|ką|ko|ek|kom|kami|kach)',
'(nie)?pierd(|a|owi|em|dzie|y|ów|om|ami|ach)',
'(nie)?pierd(oła|oły|ołowi|ole|ołę|ołą|ole|oło|oły|ołów|ół|ołom|ołami|ołach)',
'(nie)?pierdół(ecz)?(ka|ki|ce|kę|ką|ce|ko|ek|kom|kami|kach)',
'(nie)?gównoja(d|da|dowi|du|da|dem|dzie|dy|dów|dom|dami|dach)',
'(nie)?kur(wa|wy|wie|wą|wo|ew|wom|wami|wach)',
'(nie)?kurew(ecz)?(ka|ki|ce|kę|ką|ko|ek|kom|kami|kach)',
'(nie)?kurwiszon(|a|owi|u|em|ie|y|ów|om|ami|ach)',
'(nie)?kurwiszon(ecz)?(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?kurwiszcz(ę|a|u|owi|a|em||om|ami|ach)',
'(nie)?(c?hujisk|siurzysk|kutasisk|fiucisk|cipsk|piździsk|cipsk|dupsk|kurwisk)(o|a|u|owi|iem|ów||om|ami|ach)',
'(nie)?(kurwiarz|dziwkarz)(|a|u|owi|a|em|e|y|ów|om|ami|ach|e)',
'(nie)?kurwic(a|y|ę|ą|o|e||om|ami|ach)',
'(nie)?kurwidoł(ecz)?(ek|ka|ku|kowi|ka|kiem|ki|ków|kom|kami|kach)',
'(nie)?kurew?stw(o|a|u|em|ie|om|ami|ach)',
'(nie)?dziw(ecz)?(ka|ki|ce|kę|ką|ce|ko|ek|kom|kami|kach)',
'(nie)?dziw(a|y|ie|ę|ą|o||om|ami|ach)',
'(nie)?bla(dź|dzi|dzią|dzi|dzio|dzie|dziom|dziami|dziach)',
'(nie)?skurwysyn(|a|owi|em|ie|u|y|ów|om|ami|ach)',
'(nie)?skurwysyn(ecz)?(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?skurwiel(|a|owi|em|u|e|ów|om|i|ami|ach)',
'(nie)?skurwiel(ecz)?(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?jeba(k|ka|ki|ce|kowi|kę|ką|ko|k|ków|kom|ki|ków|kami|kach)',
'(nie)?jebu(t|ta|tu|towi|ta|tem|cie|ty|tom|tami|tach)',
'(nie)?(matko|kozo|owco)jebc(a|y|owi|y|ę|ą|y|o|ze|ów|om|ami|ach)',
'(nie)?(po|z)jeb(|a|u|owi|em|ie|y|owie|ów|om|ami|ach)',
'(nie)?(po|z)jeb(ecz)?(ek|ka|kowi|kiem|ku|ki|ków|kom|kami|kach)',
'(nie)?(dupo|pizdo|cipo)(właz|liz)(|a|owi|em|ie|y|owie|ów|om|ami|ach)',
  '(nie)?gwiazdojeb(|a|u|owi|em|ie|y|ów|om|ami|ach)',
  '(nie)?lachocią(g|ga|gu|gowi|ga|giem|gu|że|gi|gów|gom|gami|gach|gi)',
  '(nie)?obciąga(ra|ry|rze|rę|rą|ro|r|rom|rami|rach)',
// kot   (nie ma) kota   kotu   (widzę) kota   z kotem   o kocie   kocie!
// koty  (nie ma) kotów  kotom  (widzę) koty   z kotami  o kotach  koty!
'(nie)?wypizdow(o|a|u|owi|em|ie|y|ów|om|ami|ach)',
// przymiotniki i przysłówki od tych rzeczowników
'(nie)?(c?hujk?ow|siurk?ow|dupn|gównian)((y|ego|emu|ym|e|ych|ymi|ych)|(a|ej|ą|e|ych|ym|ymi))',
'(nie)?(naj)?(c?hujk?ow|siurk?ow|dupniej|gówniań)((szy|szego|szemu|szym|sze|szych|szymi|szych)|(sza|szej|szą|sze|szych|szym|szymi))',
'(nie)?c?hujk?owo',
'(nie)?(naj)?c?hujk?owiej',
'(nie)?siurk?owo',
'(nie)?(naj)?siurk?owiej',
'(nie)?(kurews|skurwysyńs|skurwiels|gwiazdojebs|jebac|bladz)((ki|kiego|kiemu|kim|ki|cy|kich|kim|kie|kimi|kich)|(ka|kiej|ką|kie)|(ko))',
'(nie)?pierdołowa(ty|tego|temu|tego|tym|ci|tych|tym|tymi|tych|ta|tej|tą|te)',
// czasowniki
// wzór:
// tańczę tańczysz tańczy tańczymy tańczycie tańczą
// tańczyć
// tańczyłem tańczyłeś tańczył tańczyliśmy tańczyliście tańczyli
// tańczyłam tańczyłaś tańczyła tańczyłyśmy tańczyłyście tańczyły
// taniec (z odmianą jak rzeczownik, i z nie)
// tańczący, tańcząca (z odmianą jak rzeczownik, i z nie)
// tańczony, tańczona (z odmianą jak rzeczownik, i z nie)
  // tańczywszy
// przedrostki: za, po, pod, prze, wy, z, niedo, obe
'oc?huj((eć|ałem|ałeś|ał|eliśmy|eliście|eli|ałam|ałaś|ała|ałyśmy|ałyście|ały|ało)|(enie|enia|eniu|eniem|eń|eniom|eniami|eniach)|awszy)',	
'(nie)?oc?huj(ały|ałego|ałemu|ałym|ałe|ałych|ałym|ałymi|ałych|ała|ałej|ałą|ałe)',
'(((za?|po|prze|wy|niedo)?rucha)|((z[ea]|po|prze|wy|niedo|o|obe?)?(szcza|sra)))((m|sz||my|cie|ją)|(ć)|(łem|łeś|ł|liśmy|liście|li)|(łam|łaś|ła|łyśmy|łyście|ły)|ło|wszy)',
'(nie)?((za|po|prze|wy|niedo)(rucha|szcza|sra)|(rucha|szcza|sra)|(zrucha|zeszcza|zesra)|(obeszcza|obesra))((nie|nia|niu|niem|niu|nia|ń|niom|niami|niach)|(jący|jącego|jącemu|jącego|jącym|jących|jącymi|jących|jące)|(ny|nego|nemu|nym|ni|nych|nym|nymi|ne|nej|ną))',
'(za|po|prze|przy|wy|z|niedo)?jeb((ię|iesz|ie|iemy|iecie|ią|ać|ałem|ałeś|ał|aliśmy|aliście|ali|ałam|ałaś|ała|ałyśmy|ałyście|ały|ało)|awszy)',
'(nie)?(za|po|prze|przy|wy|z|niedo)?jeb((anie|ania|aniu|aniem|ań|aniom|aniami|aniach)|(iący|iącego|iącemu|iącym|iący|iących|iącym|iącymi|iących|iąca|iącej|iącą|iące)|(any|anego|anemu|anym|ani|anych|anymi|ana|anej|aną|ane))',
'(za|po|prze|przy|wy|s|niedo)?kurw((ię|isz|i|imy|icie|ią|ić|iłem|iłeś|ił|iliśmy|iliście|ili|iłam|iłaś|iła|iłyśmy|iłyście|iły|iło)|iwszy)',
'(nie)?(za|po|prze|przy|wy|s|niedo)?kurw((ienie|ienia|ieniu|ieniem|ień|ieniom|ieniami|ieniach)|(iący|iącego|iącemu|iącym|iący|iących|iącym|iącymi|iących|iąca|iącej|iącą|iące))',
'(nie)?(za|po|prze|przy|wy?|s|niedo)kurw((iany|ianego|ianemu|ianym|iani|ianych|ianymi|iana|ianej|ianą|iane))',
'(nie)?(za|po|prze|przy|wy?|s|niedo)kurw((iony|ionego|ionemu|ionym|ieni|ionych|ionymi|iona|ionej|ioną|ione))',
'(za|p?o|p?od|prze|przy|wy|s)?pierdol(ę|isz|i|imy|icie|ą|ić|iłem|iłeś|ił|iliśmy|iliście|ili|iłam|iłaś|iła|iłyśmy|iłyście|iły|iło|iwszy)',
'(nie)?(za|p?o|p?od|prze|przy|wy|s)?pierdol((enie|enia|eniu|eniem|eń|eniom|eniami|eniach)|(ący|ącego|ącemu|ącym|ący|ących|ącym|ącymi|ących|ąca|ącej|ącą|ące)|(ony|onego|onemu|onym|eni|onych|onymi|ona|onej|oną|one))'
];