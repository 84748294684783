import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./LineupCards.scss";
import {lineup} from "./lineupContent";

function LineupCards() {
  return (
    <div className="lineup-container">
      {window.innerWidth > 960 ? (
        <div className="col-10 mx-auto">
          <h1>Line Up</h1>
          <div className="lineup-cards-wraper">
            <Row className="row">
              <Col md={1} className="side">
                <div className="side__text">PIĄTEK</div>
              </Col>
              <Col className="lineup-col">
                <Row>
                  {lineup.friday.map((artist, index) => (
                    <Col className="col" key={index.toString()}>
                      <img
                        src={`/images/lineup/${artist.imgName}`}
                        alt={artist.name}
                      />
                      <div className="lineup__text">
                        <h2>{artist.hour}</h2>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col md={1} className="side">
                <div className="side__text">PIĄTEK</div>
              </Col>
            </Row>

            <Row className="row">
              <Col md={1} className="side">
                <div className="side__text">SOBOTA</div>
              </Col>
              <Col className="lineup-col">
                <Row>
                  {lineup.saturday.map((artist, index) => (
                    <Col className="col" key={index.toString()}>
                      <img
                        src={`/images/lineup/${artist.imgName}`}
                        alt={artist.name}
                      />
                      <div className="lineup__text">
                        <h2>{artist.hour}</h2>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col md={1} className="side">
                <div className="side__text">SOBOTA</div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="col-10 mx-auto">
          <h1>Line Up</h1>
          <div className="lineup-cards-wraper">
            <Row className="row">
              <p>Piątek</p>
              {lineup.friday.map((artist, index) => (
                <Col className="col" xs={6} sm={3} key={index.toString()}>
                  <img
                    src={`/images/lineup/${artist.imgName}`}
                    alt={artist.name}
                  />
                  <div className="lineup__text">
                    <h2>{artist.hour}</h2>
                  </div>
                </Col>
              ))}
            </Row>
            <Row className="row">
              <p>Sobota</p>
              {lineup.saturday.map((artist, index) => (
                <Col className="col" xs={6} sm={3} key={index.toString()}>
                  <img
                    src={`/images/lineup/${artist.imgName}`}
                    alt={artist.name}
                  />
                  <div className="lineup__text">
                    <h2>{artist.hour}</h2>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

export default LineupCards;
