export function Logo() {
    return (
        <span>
            <svg width="100%" height="2rem" viewBox="0 0 850 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2 }}>
                <g transform="matrix(1,0,0,1,-1297,-640.674)">
                    <g transform="matrix(1.02946,0,0,0.634289,-39.1116,236.726)">
                        <rect id="CZARNY" x="1297.87" y="636.851" width="825.674" height="157.657" style={{ fill: "none" }} />
                        <g id="CZARNY1">
                            <g transform="matrix(0.554411,0,0,0.877558,1373.17,433.062)">
                                <g transform="matrix(1,0,0,1,-243.302,-14.8397)">
                                    <path d="M189.964,403.788L128.74,265.537L179.015,265.537L213.984,358.201L249.288,265.537L299.787,265.537L238.005,403.788L189.964,403.788Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,-252.929,-14.8397)">
                                    <path d="M409.89,403.788L398.39,377.514L328.677,377.412L317.189,403.788L278.224,403.788L337.143,267.31L390.889,267.459L449.494,403.788L409.89,403.788ZM341.631,347.054L386.317,346.87L363.461,295.861L341.631,347.054Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,262.832,-181.93)">
                                    <path d="M675.27,571.696L663.77,545.422L594.057,545.32L582.569,571.696L543.605,571.696L602.523,435.218L656.269,435.367L714.874,571.696L675.27,571.696ZM607.011,514.961L651.697,514.778L628.841,463.769L607.011,514.961Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,617.336,-183.675)">
                                    <path d="M675.27,571.696L663.77,545.422L594.057,545.32L582.569,571.696L543.605,571.696L602.523,435.218L656.269,435.367L714.874,571.696L675.27,571.696ZM607.011,514.961L651.697,514.778L628.841,463.769L607.011,514.961Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,-243.302,-14.8397)">
                                    <path d="M582.155,357L582.598,403.788L546.265,403.788L545.999,362.313C545.873,359.971 545.139,357.267 542.979,355.954C540.154,354.236 537.635,354.391 534.923,354.398L485.89,354.398L485.89,403.788L450.727,403.788L450.727,265.537L509.828,265.537C523.384,265.537 534.966,266.4 544.574,268.125C554.182,269.85 561.835,272.413 567.533,275.813C573.231,279.213 577.383,283.234 579.99,287.878C582.597,292.521 583.9,297.963 583.9,304.205C583.9,311.512 581.666,318.033 577.197,323.767C572.728,329.501 561.692,333.726 552.382,337.176C552.382,337.176 567.056,336.35 573.911,341.665C580.198,346.538 582.415,351.285 582.155,357ZM485.851,326.612L499.593,326.612C508.978,326.612 534.233,328.196 538.255,325.151C542.277,322.106 544.288,317.445 544.288,311.167C544.288,305.015 542.277,300.416 538.255,297.37C534.233,294.325 508.978,294.874 499.593,294.874L485.851,294.874L485.851,326.612Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,221.408,-192.364)">
                                    <path d="M208.063,585.768C188.549,585.768 167.251,581.164 154.552,574.017C141.853,566.87 139.038,550.026 136.357,536.726L168.159,536.259C170.217,555.844 194.114,556.589 212.394,556.589C220.582,556.589 227.032,555.406 231.745,553.04C236.457,550.674 238.766,547.503 238.671,543.526C238.48,541.058 239.144,538.144 236.525,536.293C233.907,534.442 230.708,532.985 226.117,531.613C221.525,530.241 214.803,529.558 209.109,528.766C203.412,527.974 197.266,527.616 191.934,526.861C186.967,526.159 181.746,525.157 177.116,524.24C172.773,523.38 168.327,522.832 164.151,521.361C159.674,519.784 154.387,518.006 150.254,514.778C146.122,511.549 142.825,507.522 140.364,502.696C137.903,497.871 136.673,492.437 136.673,486.397C136.673,472.163 144.009,460.724 158.682,452.079C173.355,443.435 185.607,443.415 204.153,443.415C223.667,443.415 239.476,446.771 251.579,453.483C263.682,460.195 271.977,475.594 271.733,490.254L242.013,490.255C237.913,478.318 223.222,474.533 202.358,474.533C192.663,474.533 185.924,474.148 180.34,476.137C174.756,478.125 171.964,482.548 171.964,486.091C171.964,488.639 174.39,491.798 176.934,493.133C180.495,495 187.791,496.229 193.327,497.295C199.037,498.395 205.976,499.078 211.198,499.73C215.676,500.288 233.185,501.32 247.805,507.261C255.974,510.581 263.529,514.711 267.643,520.652C271.867,526.75 273.243,536.84 273.243,542.247C273.243,555.112 269.194,566.268 256.941,574.068C244.689,581.868 228.396,585.768 208.063,585.768Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(0.942176,0,0,1,231.402,-193.11)">
                                    <path d="M395.309,583.53C387.638,585.208 379.519,586.047 370.954,586.047C362.388,586.047 354.289,585.208 346.654,583.53C339.02,581.852 331.832,579.164 325.092,575.466C318.351,571.769 312.542,567.201 307.663,561.763C302.785,556.324 298.949,549.612 296.156,541.626C293.363,533.64 291.966,524.768 291.966,515.011C291.966,505.44 293.381,496.708 296.212,488.815C299.042,480.922 302.915,474.241 307.831,468.772C312.747,463.303 318.575,458.704 325.315,454.975C332.056,451.246 339.281,448.511 346.989,446.771C354.698,445.031 362.835,444.161 371.401,444.161C381.977,444.161 391.902,445.575 401.175,448.402C410.447,451.23 418.789,455.441 426.2,461.034C433.611,466.628 439.477,474.055 443.796,483.315C448.116,492.575 450.276,503.14 450.276,515.011C450.276,524.768 448.861,533.64 446.031,541.626C443.201,549.612 439.346,556.324 434.468,561.763C429.589,567.201 423.761,571.769 416.983,575.466C410.205,579.164 402.981,581.852 395.309,583.53ZM370.954,557.351C382.835,557.351 392.293,553.688 399.326,546.362C406.359,539.036 409.875,528.512 409.875,514.789C409.875,501.639 406.315,491.347 399.195,483.914C392.074,476.48 382.748,472.764 371.216,472.764C359.247,472.764 349.724,476.463 342.647,483.86C335.571,491.257 332.032,501.567 332.032,514.789C332.032,528.584 335.505,539.126 342.451,546.416C349.396,553.706 358.897,557.351 370.954,557.351Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,221.408,-192.364)">
                                    <path d="M442.11,583.903L442.11,445.652L475.814,445.652L538.658,528.874L538.658,445.652L574.378,445.652L574.378,583.903L540.446,583.903L477.601,498.97L477.601,583.903L442.11,583.903Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(1,0,0,1,835.924,-374.225)">
                                    <path d="M147.398,764.018L147.398,625.768L184.636,625.768L184.636,732.52L268.807,732.52L268.807,764.018L147.398,764.018Z" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                                <g transform="matrix(0.786675,0,0,1,906.373,-374.225)">
                                    <rect x="267.499" y="625.768" width="46.476" height="138.251" style={{ fill: "white", fillRule: "nonzero" }} />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </span>
    )
}