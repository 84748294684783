import {useCallback, useLayoutEffect, useState} from 'react';

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    const handleWindowResize = useCallback(() => {
        setSize([window.innerWidth, window.innerHeight]);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);
    return size;
}