import React, {createContext, useState} from "react";
import Navbar from "./components/Navbar";
import "./App.scss";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/home/home";
import GalleryFolderView from "./pages/archive/galleryFolderView";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer";
import MessageToast from "./components/messageToast/MessageToast";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import Container from "react-bootstrap/esm/Container";
import NotFound from "./pages/404/404";
import {Loader} from "./components/Loader";
import {ImageContextProvider} from "./Context/ImageContextProvider";

export const UserContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | Function>(() => {
});

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <Container fluid="true" style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
      <Router>
        <UserContext.Provider value={setLoading}>
          <ImageContextProvider>
            {loading && <Loader/>}
            <Navbar/>
            <MessageToast/>
            <Routes>
              <Route path="/" Component={Home}/>
              <Route path="/archive/:year" Component={GalleryFolderView}/>
              <Route path='/404' Component={NotFound}/>
              <Route
                path="*"
                element={<Navigate to="/404" replace/>}
              />
            </Routes>
            <Footer/>
          </ImageContextProvider>
        </UserContext.Provider>
      </Router>
    </Container>
  );
}

export default App;
