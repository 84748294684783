export const faqContent = [
  {
    question:
      "Czy mo\u017cna wyj\u015b\u0107 z terenu imprezy i ponownie na niego wr\u00f3ci\u0107?",
    answer: "Niestety nie ma takiej mo\u017cliwo\u015bci.",
  },
  {
    question:
      "Mam poni\u017cej 18 lat. Czy w takim wypadku mog\u0119 uczestniczy\u0107 w Juwenaliach?",
    answer:
      "Nie, ze wzgl\u0119du na regulamin imprezy, wst\u0119p na Juwenalia maj\u0105 tylko osoby pe\u0142noletnie.",
  },
  {
    question:
      "Zgubi\u0142em okulary podczas Juwenali\u00f3w. Gdzie mog\u0119 si\u0119 zg\u0142osi\u0107?",
    answer:
      "Po 13 maja na naszej stronie pojawi si\u0119 informacja, gdzie mo\u017cna odebra\u0107 wszystkie rzeczy, kt\u00f3re uda nam si\u0119 znale\u017a\u0107 po Juwenaliach na terenie stadionu.",
  },
  {
    question: "Czy mo\u017cna kupi\u0107 bilet na bramce?",
    answer:
      "Tak, b\u0119dzie taka mo\u017cliwo\u015b\u0107, je\u015bli pula internetowa nie wyprzeda si\u0119 do tego czasu.",
  },
  {
    question: "Gdzie mog\u0119 kupi\u0107 wod\u0119?",
    answer:
      "Wod\u0119 b\u0119dzie mo\u017cna kupi\u0107 w punktach gastronomicznych.",
  },
  {
    question:
      "Czy przy wej\u015bciu na Juwenalia wymagany jest bilet w postaci papierowej? Czy b\u0119d\u0105 akceptowane bilety w formie elektronicznej?",
    answer:
      "Na bramkach nie s\u0105 wymagane bilety w postaci papierowej. Bilety elektroniczne b\u0119d\u0105 akceptowane.",
  },
  {
    question: "Gdzie znajd\u0119 tegoroczny Line-Up?",
    answer:
      "Wszystkie informacje dotycz\u0105ce artyst\u00f3w znajduj\u0105 si\u0119 zar\u00f3wno na naszym Facebook\u2019u, Instagramie jak i stronie.",
  },
  {
    question: "O kt\u00f3rej zagra \u2026.?",
    answer:
      "Dok\u0142adny harmonogram zostanie podany prawdopodobnie na tydzie\u0144 przed wydarzeniem na wszystkich naszych Social Mediach.",
  },
  {
    question: "Gdzie znajduj\u0105 si\u0119 toalety?",
    answer:
      "Szczeg\u00f3\u0142owa mapa ca\u0142ego terenu imprezy znajduje si\u0119 na naszych Social Mediach oraz w aplikacji dost\u0119pnej w Google Play i App Store.",
  },
  {
    question:
      "Czy na teren mog\u0119 wnie\u015b\u0107 w\u0142asne picie/jedzenie?",
    answer: "Nie. Zapraszamy do zapoznania si\u0119 z Regulaminem.",
  },
  {
    question: "O kt\u00f3rej ko\u0144czy si\u0119 ostatni koncert?",
    answer:
      "Koniec koncert\u00f3w przewidujemy oko\u0142o p\u00f3\u0142nocy w sobot\u0119.",
  },
  {
    question:
      "Czy wystarczy okazanie legitymacji lub dowodu to\u017csamo\u015bci w aplikacji mObywatel?",
    answer: "Tak, wystarczy.",
  },
  {
    question:
      "Czy na terenie imprezy b\u0119d\u0105 akceptowane karty p\u0142atnicze?",
    answer: "Tak",
  },
  {
    question:
      "Czy na terenie imprezy znajduje si\u0119 szatnia/depozyt/inne miejsce, w kt\u00f3rym b\u0119dzie mo\u017cna zostawi\u0107 swoje rzeczy?",
    answer: "Nie",
  },
  {
    question: "W jakiej cenie b\u0119d\u0105 sprzedawane bilety na bramkach?",
    answer:
      "Na bramkach bilety będą dostępne w następujących cenach:\nStudent PW - 70 PLN\nStudent innej Uczelni - 80 PLN\nReszta \u015awiata - 100 PLN",
  },
  {
    question: "Czy na teren imprezy mog\u0119 wej\u015b\u0107 z psem?",
    answer:
      "Nie, na terenie imprezy obowi\u0105zuje zakaz wprowadzania zwierz\u0105t.",
  },
  {
    question:
      "Jestem alergikiem, czy b\u0119d\u0105 znane sk\u0142ady produkt\u00f3w?",
    answer:
      "Tak, informacje o sk\u0142adzie da\u0144 b\u0119d\u0105 podane w punkcie zakupu dania.",
  },
  {
    question:
      "Czy w ofercie gastronomicznej b\u0119d\u0105 dost\u0119pne produkty vege?",
    answer: "Tak",
  },
  {
    question:
      "Czy b\u0119d\u0105 toalety dla os\u00f3b z niepe\u0142nosprawno\u015bciami?",
    answer: "Tak",
  },
];
