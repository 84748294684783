import React, {createRef, useContext, useEffect, useRef, useState} from "react";
import "./Archivecard.scss";
import {ArchiveEvent} from "../pages/archive/archiveContent";
import {Link} from "react-router-dom";
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {ImageContext} from "../Context/ImageContextProvider";

function Archivecard({event, changePicture = false}: { event: ArchiveEvent, changePicture?: boolean }) {
  const filteredImages = useContext(ImageContext).year.get(String(event.year)) ?? [];

  const [imgId, setImgId] = useState(0);
  const elementsRef = useRef(filteredImages.map(() => createRef<HTMLImageElement>()));
  const nodeRef = elementsRef.current[imgId];

  useEffect(() => {
    elementsRef.current = filteredImages.map(() => createRef<HTMLImageElement>());
    setImgId(Math.min(imgId, filteredImages.length));
  }, [filteredImages]);

  useEffect(() => {
    if (changePicture && filteredImages.length > 0) {
      let newPictureID = imgId;
      while (newPictureID === imgId) {
        newPictureID = Math.floor(Math.random() * filteredImages.length) % filteredImages.length;
      }
      setImgId(newPictureID);
    }
  }, [changePicture]);

  return (
    <div className="gallery-home-div">
      <Link className="link" to={`archive/${event.year}`}>
        <div className="gallery-home-img-wrapper">
          <SwitchTransition mode="in-out">
            <CSSTransition
              key={imgId}
              nodeRef={nodeRef}
              addEndListener={(done: any) => {
                nodeRef.current?.addEventListener("transitionend", done, false);
              }}
              unmountOnExit={true}
              classNames="fade"
            >
              <img ref={nodeRef} src={filteredImages[imgId]}
                   className="gallery-home-img" alt={filteredImages[imgId]}/>
            </CSSTransition>
          </SwitchTransition>
        </div>
        <div className="gallery-home-card-content">
          <p className="name">{event.name}</p>
        </div>
      </Link>
    </div>
  );
}

export default Archivecard;
