export type ArchiveEvent = {
  name: string,
  year: number,
}

export const events: ArchiveEvent[] = [
  {
    name: "Varsonalia - Juwenalia PW 2023",
    year: 2023,
  },
  {
    name: "Juwenalia PW 2022",
    year: 2022,
  },
  {
    name: "Juwenalia PW 2019",
    year: 2019,
  },
  {
    name: "Juwenalia PW 2018",
    year: 2018,
  },
  {
    name: "Juwenalia PW 2017",
    year: 2017,
  },
  {
    name: "Juwenalia PW 2016",
    year: 2016,
  },
]
