import {useContext, useEffect, useState} from 'react';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import ToastContainer from 'react-bootstrap/esm/ToastContainer';
import "./MessageToast.scss"
import MessageModal from './MessageModal';
import axios from 'axios';
import {UserContext} from 'src/App';
import {antispam_regex_templates} from "./antispam_regex_templates";

function MessageToast() {
  const [showComponent, setShowComponent] = useState(false);

  const [minimised, setMinimised] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [author, setAuthor] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<number>(0);
  const [validated, setValidated] = useState(false);
  const setLoading = useContext(UserContext);
  const maxId: number = 4294967296;
  const minCharactersInAuthor = 3;
  const minCharactersInMessage = 5;

  const eventStart = new Date("May 17, 2024 15:00:00").getTime();
  const eventEnd = new Date("May 19, 2024 00:00:00").getTime();

  const regex_filters: RegExp[] = antispam_regex_templates.map((item) => new RegExp(item));

  useEffect(() => {
    const now = new Date().getTime();

    // DEBUG
    // const time_until_start = Math.floor((eventStart - now) / 1000 / 60 / 60);
    // const time_until_end = Math.floor((eventEnd - now) / 1000 / 60 / 60);
    // console.log(time_until_start > 0 ? `Event will start in ${time_until_start} hours` : `Event started ${time_until_start * -1} hours ago`);
    // console.log(time_until_end > 0 ? `Event will end in ${time_until_end} hours` : `Event ended ${time_until_end * -1} hours ago`);

    if (now > eventEnd) {
      // event already ended
      setShowComponent(false);
    } else if (now >= eventStart) {
      // event takes place right now
      setShowComponent(true);
    } else {
      // event is yet to start, so let's create timer to show the toast when event starts
      const timeUntilTarget = eventStart - now;
      const timer = setTimeout(() => {
        // double check if it's not too early
        if (Date.now() >= eventStart) {
          setShowComponent(true);
        }
      }, timeUntilTarget);

      // Cleanup timeout if the component is unmounted before the timer ends
      return () => clearTimeout(timer);
    }
  }, []);

  function axiosPost() {
    setLoading(true);

    // create ID based on current time, add random at the end to try to prevent ID clash
    const possibleId: number = new Date((Date.now() - eventStart) * 7 + Math.floor(Math.random() * 7)).getTime();
    // in case possibleId is over minimum or maximum, just throw any number and hope for the best. What can go wrong anyway at this point?
    const id: number = 0 < possibleId && possibleId < maxId ? possibleId : Math.floor(Math.random() * maxId);
    // console.log(`${id} (${Math.floor(id / maxId * 100)}%)`);

    axios.post(
      `https://prod-126.westeurope.logic.azure.com/workflows/79bbfe7bb24c4571a8228badc2ff0e03/triggers/manual/paths/invoke/commentsOps?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gEGUhIKegJ8MY5jxHHblsm-VPJAVPwnOVk6fep1au0c`,
      {
        jsonrpc: "2.0",
        id: id,
        method: "newcomment",
        params: {
          name: author,
          content: message,
        },
      })
      .then(res => {
        setStatus(res.status);
        setMinimised(!minimised);
        setAuthor("");
        setMessage("");
        setValidated(false);
        setLoading(false);
        setShowModal(true);
      })
      .catch(err => {
        setStatus(err.response.status);
        setValidated(false);
        setLoading(false);
        setShowModal(true);
      })
  }

  function handleSubmit(e: any) {
    setValidated(true);
    e.preventDefault();
    if (author && message) {
      if (new Date().getTime() < eventStart) {
        setStatus(69);
        setMinimised(!minimised);
        setValidated(false);
        setShowModal(true);
      } else if (new Date().getTime() > eventEnd) {
        setStatus(70);
        setMinimised(!minimised);
        setValidated(false);
        setShowModal(true);
      } else if (author.length < minCharactersInAuthor) {
        setStatus(71);
        setValidated(false);
        setShowModal(true);
      } else if (message.length < minCharactersInMessage) {
        setStatus(72);
        setValidated(false);
        setShowModal(true);
      } else if (regex_filters.map((regex) => regex.exec(message.toLowerCase() + " " + author.toLowerCase()) !== null).indexOf(true) > -1) {
        setStatus(73);
        setShowModal(true);
      } else {
        axiosPost();
      }
    }
  }

  if (!showComponent)
    return null;

  return (
    <div className={minimised ? "message-toast fade-out" : "message-toast"}>
      <MessageModal show={showModal} setShow={setShowModal} status={status}/>
      {/* <ToastContainer className="px-5" > */}
      <ToastContainer>
        <Toast onClose={() => setMinimised(!minimised)}>
          <Toast.Header closeButton={false} onClick={() => setMinimised(!minimised)} style={{cursor: "pointer"}}>
            {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
            <span className="header-title">Wyślij wiadomość na telebim</span>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <span className="caption">Autor <small>(3-20 znaków)</small></span>
                  <Form.Control
                    required
                    minLength={3}
                    maxLength={20}
                    name="author"
                    type="text"
                    value={author}
                    onChange={(e: any) => {
                      setAuthor(e.target.value)
                    }}
                  />
                </Col>
              </Row>
              <Row className="py-3">
                <Col>
                  <span className="caption">Wiadomość <small>(5-100 znaków)</small></span>
                  <Form.Control
                    required
                    minLength={5}
                    maxLength={100}
                    as="textarea"
                    name="message"
                    className="message"
                    value={message}
                    onChange={(e: any) => {
                      setMessage(e.target.value)
                    }}
                  />
                </Col>
              </Row>
              <Button
                type="submit"
                className="toast-button"
                // disabled={disable}
                // onClick={(e: any) => {
                //     e.preventDefault();
                //     // onSubmit();
                // }}
              >
                Wyślij!
              </Button>
            </Form>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default MessageToast;