import React, {useEffect, useLayoutEffect, useState} from "react";
import "../../App.scss";
import "./homepageVideo.scss";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import {useWindowSize} from "../../Hooks/useWindowSize";
import {useWindowScroll} from "../../Hooks/useWindowScroll";

function HomepageVideo() {
  const dimensions = useWindowSize();
  const scroll = useWindowScroll();

  const [syrenkaBasicOffset, setSyrenkaBasicOffset] = useState<number>(0);
  const [syrenkaScrollMultiplier, setSyrenkaScrollMultiplier] = useState<number>(0);
  const [syrenkaStyle, setSyrenkaStyle] = useState<React.CSSProperties>({});

  useLayoutEffect(() => {
    if (dimensions[1] === 0) {
      console.warn("Dimensions not set, cannot compute offset");
      return;
    }
    setSyrenkaScrollMultiplier(Math.pow(window.scrollY / dimensions[1], 2));
  }, [scroll]);

  useLayoutEffect(() => {
    if (dimensions[1] === 0) {
      console.warn("Dimensions not set, cannot compute offset");
      return;
    }
    setSyrenkaBasicOffset(dimensions[0] / dimensions[1] > (2900 / 1920) ?
      (dimensions[0] - dimensions[1] * 2900 / 1920) / 2 : 0);
  }, [dimensions, ...dimensions]);

  const syrenkaMovementOffset = (Math.min(dimensions[1] * 2900 / 1920, dimensions[0]) - dimensions[1] / 3) * 2 / 3;

  useLayoutEffect(() => {
    setSyrenkaStyle((prevState) => ({...prevState, "left": Math.min(syrenkaBasicOffset + syrenkaMovementOffset * syrenkaScrollMultiplier, dimensions[0] - dimensions[1] / 3)}));
  }, [syrenkaBasicOffset, syrenkaScrollMultiplier]);

  const end = new Date("May 17, 2024 15:00:00").getTime();

  const _second = 1000;
  const _minute = 60;
  const _hour = 60;
  const _day = 24;
  const [timeLeft, setTimeLeft] = useState<number>(end - new Date().getTime());

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(end - new Date().getTime());
    }, 1000);
  }, [])

  return (
    <div className="hero-container">
      <img className="syrenka" src="images/syrenka.webp" alt="Syrenka" style={syrenkaStyle}/>
      {/*<video src="/videos/background-video.mp4" autoPlay loop muted />*/}
      {timeLeft <= 0 ?
        <h1>VARSONALIA {window.innerWidth < 961 && <br/>}PW 2024</h1>
        :
        <>
          <h1>VARSONALIA {window.innerWidth < 961 && <br/>}PW 2024</h1>
          <Row className="countdown">
            <Col className="time-element" xs={{span: 6}} sm={{span: 3}}>
              <span id="days" className="time-span">{Math.floor(timeLeft / (_second * _hour * _minute * _day))}</span>Dni
            </Col>
            <Col className="time-element" xs={{span: 6}} sm={{span: 3}}>
              <span id="hours" className="time-span">{Math.floor(timeLeft % (_second * _hour * _minute * _day) / (_second * _hour * _minute))}</span>Godzin
            </Col>
            <Col className="time-element" xs={{span: 6}} sm={{span: 3}}>
              <span id="minutes" className="time-span">{Math.floor(timeLeft % (_second * _hour * _minute) / (_second * _hour))}</span>Minut
            </Col>
            <Col className="time-element" xs={{span: 6}} sm={{span: 3}}>
              <span id="seconds" className="time-span">{Math.floor(timeLeft % (_second * _hour) / _second)}</span>Sekund
            </Col>
          </Row>
        </>
      }
    </div>
  );
}

export default HomepageVideo;
