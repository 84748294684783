export const lineup = {
  friday: [
    {
      name: "Michał Szczygieł",
      imgName: "szczygieł.webp",
      hour: "18:00",
    },
    {
      name: "Karaś / Rogucki",
      imgName: "karaś.webp",
      hour: "19:15",
    },
    // {
    //   name: "Daria Zawiałow",
    //   imgName: "daria.webp",
    //   hour: "20:45",
    // },
    {
      name: "Gibbs",
      imgName: "gibbs.webp",
      hour: "20:45",
    },
    {
      name: "Wilki",
      imgName: "wilki.webp",
      hour: "22:15",
    },
  ],
  saturday: [
    {
      name: "Enej",
      imgName: "enej.webp",
      hour: "17:40",
    },

    {
      name: "Feel",
      imgName: "feel.webp",
      hour: "19:10",
    },
    {
      name: "Kombii",
      imgName: "kombii.webp",
      hour: "20:40",
    },
    {
      name: "Lady Pank",
      imgName: "ladypank.webp",
      hour: "22:10",
    },
  ],
};